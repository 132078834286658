







































































































import { Vue, Component, InjectReactive } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { inject } from 'inversify-props';
import { AgGridVue } from '@ag-grid-community/vue';
import { SelectionChangedEvent, GridReadyEvent } from '@ag-grid-community/core';
import { sumBy } from 'lodash';
import DateRangeFilter from '@/components/date-range-filter.vue';
import { IDateRangeConfig } from '@/interfaces/date-range-config.interface';
import OrderModel from '@/models/crm/order.model';
import Tooltip from '@/components/tooltip.vue';
import DataGridFilter from '@/components/data-grid-filter.vue';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import OrderService from '@/services/crm/order.service';
import RouterService from '@/services/router.service';
import CrmOrderDetails from '@/components/crm/order-details.vue';
import ContentDialog from '@/components/content-dialog.vue';
import { DateHelper } from '@/utils/helpers/date-helper';
import { ArrayHelper } from '@/utils/helpers/array-helper';
import CrmOrderEmailView from '@/components/crm/order-email-view.vue';
import UserContactInfo from '@/models/crm/user-contact-info.model';
import ClientModel from '@/models/crm/client.model';
import AgGridWrapper from '@/components/ag-grid-wrapper.vue';
import { IGridCellEvent } from '@/interfaces/grid-cell-clicked.interface';
import { IGridConfig } from '@/interfaces/grid-config.interface';
import { NumberHelper } from '@/utils/helpers/number-helper';
import { GridHelper } from '@/utils/helpers/grid-helper';
import { IKeyValue } from '@/interfaces/key-value.interface';
import { IDialogConfig } from '@/interfaces/dialog-config.interface';

type DataGridFilterConfig = {
  keyword: string | undefined;
  issuance: (Date | undefined)[];
};

@Component({
  components: {
    DataGridFilter,
    DateRangeFilter,
    Tooltip,
    AgGridWrapper,
    AgGridVue,
    ContentDialog,
    CrmOrderDetails,
    CrmOrderEmailView,
  },
})
export default class CrmOrders extends Vue {
  @inject(InjectionIdEnum.CrmOrderService)
  private orderService!: OrderService;

  @inject(InjectionIdEnum.RouterService)
  private routerService!: RouterService;

  @InjectReactive('activeClient') readonly activeClient!: ClientModel;

  @InjectReactive('userContactInfo') readonly userContactInfo!: UserContactInfo;

  private sendOrderByEmailLimit = 10;

  grid: GridReadyEvent | null = null;

  gridSettings: IGridConfig = {
    loading: false,
    defaultSort: [{ colId: 'dataEmissao', sort: 'desc' }],
    columnDefs: [
      GridHelper.getSelectionColDef(),
      {
        headerName: `${this.$t('crm.view.order.grid.orderNumber')}`,
        field: 'codPedido',
        minWidth: 130,
        flex: 0.5,
      },
      {
        headerName: `${this.$t('crm.view.order.grid.origin')}`,
        field: 'nomeDataSource',
        flex: 0.5,
      },
      {
        headerName: `${this.$t('crm.view.order.grid.situation')}`,
        field: 'situacao',
        autoHeight: true,
      },
      {
        headerName: `${this.$t('crm.view.order.grid.canceledItems')}`,
        field: 'qtdeCancelada',
        maxWidth: 140,
        valueFormatter: (params): string => (params.value === 0 ? '' : Number(params.value).toFixed(0)),
        cellClass: 'grid-cell-centered',
      },
      {
        headerName: `${this.$t('crm.view.order.grid.issuanceDate')}`,
        field: 'dataEmissao',
        maxWidth: 155,
        valueGetter: (params): string => GridHelper.valueGetter(params, DateHelper.formatToIsoDateTimeString),
        valueFormatter: (params): string => DateHelper.formatToLocale(params.value),
        cellClass: 'dateISO',
      },
      {
        headerName: `${this.$t('crm.view.order.grid.forecastBillingDate')}`,
        field: 'dataPrevFaturamento',
        maxWidth: 155,
        valueGetter: (params): string => GridHelper.valueGetter(params, DateHelper.formatToIsoDateTimeString),
        valueFormatter: (params): string => DateHelper.formatToLocale(params.value),
        cellClass: 'dateISO',
      },
      {
        headerName: `${this.$t('crm.view.order.grid.grossValue')}`,
        field: 'valorBruto',
        maxWidth: 140,
        valueFormatter: (params): string => NumberHelper.formatToLocaleDecimal(params.value),
        cellClass: 'grid-cell-centered',
      },
      {
        headerName: `${this.$t('crm.view.order.grid.netValue')}`,
        field: 'valorLiquido',
        maxWidth: 140,
        valueFormatter: (params): string => NumberHelper.formatToLocaleDecimal(params.value),
        cellClass: 'grid-cell-centered',
      },
    ],
  };

  filters: DataGridFilterConfig = {
    keyword: undefined,
    issuance: [undefined, undefined],
  };

  items: OrderModel[] = [];

  selected: OrderModel[] = [];

  ordersToSent: OrderModel[] = [];

  predefinedIssuanceFilterRanges: IDateRangeConfig[] = this.getDateRanges();

  dialogConfig: IKeyValue<IDialogConfig> = {
    sendDetail: {
      show: false,
      model: null,
    },
    sendOrderEmail: {
      show: false,
    },
  };

  async mounted(): Promise<void> {
    this.loadItems();
  }

  async onCellClick(event: IGridCellEvent<OrderModel>): Promise<void> {
    if (!event.data) {
      return;
    }

    const loader = this.$loading.show();
    try {
      this.dialogConfig.sendDetail.model = await this.orderService.getOrder(
        event.data.idDataSource,
        event.data.codEmpresa,
        event.data.codPedido,
      );
      this.dialogConfig.sendDetail.show = true;
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }
  }

  onSelectionChanged(change: SelectionChangedEvent, selected: OrderModel[]): void {
    this.selected = selected;
  }

  onExport(selected: OrderModel[]): void {
    if (this.grid) {
      const onlySelected = !!selected.length && this.filteredItems.length !== selected.length;
      const columnKeys = this.gridSettings.columnDefs
        .filter((x) => !x.checkboxSelection)
        .map((x) => x.colId || x.field || '');

      this.grid.api.exportDataAsExcel({
        onlySelected,
        columnKeys,
        allColumns: true,
        author: 'IBtech',
        sheetName: 'Pedidos',
        fileName: OrderService.generateOrderExportFilename(new Date()),
      });
    }
  }

  onSendEmail(orders: OrderModel[]): void {
    if (!orders.length) {
      return;
    }

    if (orders.length > this.sendOrderByEmailLimit) {
      this.$notify.error(
        `${this.$tc('crm.view.order.sendOrderByEmailLimitExceed', this.sendOrderByEmailLimit, {
          selected: orders.length,
        })}`,
      );

      return;
    }

    this.ordersToSent = orders;
    this.dialogConfig.sendOrderEmail.show = true;
  }

  get filteredItems(): OrderModel[] {
    let filteredItems = this.items;

    if (this.filters.keyword) {
      const columnsToSearch = ['codPedido', 'nomeDataSource', 'situacao', 'valorBruto', 'valorLiquido'];
      filteredItems = ArrayHelper.filterByKeyword(filteredItems, columnsToSearch, this.filters.keyword);
    }

    if (this.filters.issuance && this.filters.issuance[0]) {
      const start = dayjs(this.filters.issuance[0]);
      filteredItems = filteredItems.filter(
        (item) => dayjs(item.dataEmissao).isAfter(start) || dayjs(item.dataEmissao).isSame(start),
      );
    }

    if (this.filters.issuance && this.filters.issuance[1]) {
      const end = dayjs(this.filters.issuance[1]);
      filteredItems = filteredItems.filter(
        (item) => dayjs(item.dataEmissao).isBefore(end) || dayjs(item.dataEmissao).isSame(end),
      );
    }

    return filteredItems;
  }

  get detailDialogTitle(): string {
    return `${this.$t('crm.view.order.dialog.details.title', {
      orderNumber: this.dialogConfig?.sendDetail?.model && (this.dialogConfig.sendDetail.model as OrderModel).codPedido,
    })}`;
  }

  get sendOrderEmailDialogTitle(): string {
    const orderNumber = this.ordersToSent.map((item) => `#${item.codPedido}`).join(', ');

    return `${this.$tc('crm.view.order.dialog.sendMail.title', this.ordersToSent.length, {
      orderNumber,
    })}`;
  }

  get summary(): IKeyValue<number>[] | undefined {
    const items = this.filteredItems;

    if (!items || !items.length) {
      return undefined;
    }

    return [
      {
        valorBruto: sumBy(items, 'valorBruto'),
        valorLiquido: sumBy(items, 'valorLiquido'),
        qtdeCancelada: sumBy(items, 'qtdeCancelada'),
      },
    ];
  }

  get activeFilters(): number {
    let active = 0;
    const filterKeys = Object.keys(this.filters);

    filterKeys.forEach((key) => {
      switch (key) {
        case 'issuance':
          if (this.filters[key] && (this.filters[key][0] || this.filters[key][1])) {
            active += 1;
          }
          break;
        default:
          if (this.filters[key]) {
            active += 1;
          }
      }
    });

    return active;
  }

  private getDateRanges(): IDateRangeConfig[] {
    return [
      {
        name: `${this.$t('global.currentMonth')}`,
        ...DateHelper.getCurrentMonthPeriod(),
      },
      {
        name: `${this.$t('global.lastMonth')}`,
        ...DateHelper.getLastMonthsPeriod(1),
      },
      {
        name: `${this.$t('global.lastThreeMonths')}`,
        ...DateHelper.getLastMonthsPeriod(3),
      },
      {
        name: `${this.$t('global.lastSixMonths')}`,
        ...DateHelper.getLastMonthsPeriod(6),
      },
      {
        name: `${this.$t('global.lastYear')}`,
        ...DateHelper.getLastYearsPeriod(1),
      },
      {
        name: `${this.$t('global.currentYear')}`,
        ...DateHelper.getCurrentYearPeriod(),
      },
    ];
  }

  private async loadItems(): Promise<void> {
    this.gridSettings.loading = true;
    this.items = await this.orderService.getOrders(this.getClientIdFromRoute());
    this.gridSettings.loading = false;

    if (!this.items || (this.items && this.items.filter((item) => item.qtdeCancelada).length === 0)) {
      this.gridSettings.columnDefs[4].hide = true;
      this.grid?.api.setColumnDefs(this.gridSettings.columnDefs);
    }
  }

  private getClientIdFromRoute(): string {
    if (!this.activeClient) {
      const currentRoute = this.routerService.route();
      return currentRoute.params && currentRoute.params.clientId;
    }
    return this.activeClient.cnpjCpf;
  }
}
